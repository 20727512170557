import { StaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import classNames from 'classnames'

import styles from "./styles.module.scss"

const Nav = ({ className, variant }) => (
  <StaticQuery
    query={graphql`
      query NavQuery {
        site {
          siteMetadata {
            social {
              patreon
            }
          }
        }
      }
    `}
    render={ ({ site: { siteMetadata } }) => (
      <nav className={classNames(styles.nav, styles[className])}>
        { variant === 'full' &&
          <Link
            className="p uppercase"
            to="/about"
          >
            About
          </Link>
        }
        { variant === 'full' &&
          <Link
            className="p uppercase"
            to="/shop"
          >
            Shop
          </Link>
        }
        <Link
          className="p uppercase"
          to="/submit"
        >
          Submit
        </Link>
        <a
          className="p uppercase"
          href={ siteMetadata.social.patreon }
        >
          Subscribe
        </a>
      </nav>
    ) }
  />
)

Nav.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['full', 'cta'])
}

Nav.defaultProps = {
  className: null,
  variant: 'full'
}

export default Nav
