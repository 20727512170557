/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import classNames from 'classnames'

import Footer from "../Footer"
import Header from "../Header"
// import Splash from "../Splash"

import styles from "./styles.module.scss"


const Layout = ({ className, classNameLayout, children, contentWidth, id }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          email
          social {
            instagram
            patreon
            twitter
          }
          title
        }
      }
    }
  `)

  return (
    <section className={ classNames(classNameLayout, styles.layout) }>
      { /** <Splash siteTitle={data.site.siteMetadata.title} /> **/}
      <div
        className={styles.container}
        id={id}
      >
        <Header siteTitle={data.site.siteMetadata.title} social={data.site.siteMetadata.social} />
        <div className={ className }>
          <main className={ classNames(styles.content, styles[contentWidth]) }>{children}</main>
        </div>
        <Footer email={data.site.siteMetadata.email} social={data.site.siteMetadata.social} title={data.site.siteMetadata.title}/>
      </div>
    </section>
  )
}

Layout.propTypes = {
  className: PropTypes.string,
  classNameLayout: PropTypes.string,
  children: PropTypes.node.isRequired,
  contentWidth: PropTypes.oneOf(['bleed', 'narrow', 'wide']),
  id: PropTypes.string,
}

Layout.defaultProps = {
  className: null,
  classNameLayout: null,
  contentWidth: 'wide',
  id: null,
}

export default Layout
