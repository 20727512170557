import PropTypes from "prop-types"
import React from "react"

import styles from "./styles.module.scss"

const Footer = ({ email, social, title }) => (
  <footer className={styles.footer}>
    <span
      className="p small"
    >
      <em>{title}</em>
      {` `}
      © {new Date().getFullYear()}
    </span>
    <nav
      className="p uppercase small"
    >
      { email && <a href={`mailto:${email}`}>Contact</a> }
      { social.instagram && <a href={ social.instagram }>Instagram</a>}
      { social.twitter && <a href={ social.twitter }>Twitter</a>}
    </nav>
    <span
      className="p small"
    >
      <a href="http://sarahquigley.net">Design—Sarah Quigley</a>
    </span>
  </footer>
)

Footer.propTypes = {
  email: PropTypes.string,
  siteSocial: PropTypes.shape({
    instagram: PropTypes.string,
    twitter: PropTypes.string
  }),
  title: PropTypes.string.isRequired,
}

Footer.defaultProps = {
  email: null,
  social: [],
}

export default Footer
