import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Nav from "../Nav"

import styles from "./styles.module.scss"
import logo from "../../images/logo@0.1x.png"

const Header = ({ siteTitle, social }) => (
  <header className={styles.header}>
    <div>
      <h1>
        <Link
          to="/"
        >
          <img
            alt={ `${siteTitle} logo` }
            src={ logo }
          />
          { siteTitle }
        </Link>
      </h1>
      <Nav social={ social } />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
